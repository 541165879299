angular.module('fingerink')
    .directive('dashboardDocumentRow', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'web/main/dashboard/widgets/dashboardDocumentRow.tpl.html',
            scope: {
                firma: '='
            },
            controllerAs: 'controller',
            controller: 'rowDocumentoEnviadoCtrl'
        };
    });